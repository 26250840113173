export const LOADING = "LOADING";
export const CLEAR_ALL = "CLEAR_ALL";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOGGEDIN = "LOGGEDIN";
export const SET_ANNOUNCEMENTS = "SET_ANNOUNCEMENTS";
export const SET_BULLETINS = "SET_BULLETINS";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_USERS = "SET_USERS";
export const SET_ALL_USERS = "SET_ALL_USERS";
export const SET_SENT_MESSAGES = "SET_MESSAGES";
export const SET_RECEIVED_MESSAGES = "SET_RECEIVED_MESSAGES";
export const SET_FILES = "SET_FILES";
export const SET_PROPERTY = "SET_PROPERTY";
export const SET_OPENREQUESTS = "SET_OPENREQUESTS";
export const SET_CLOSEDREQUESTS = "SET_CLOSEDREQUESTS";
export const SET_PROPERTYOPENREQUESTS = "SET_PROPERTYOPENREQUESTS";
export const SET_PROPERTYCLOSEDREQUESTS = "SET_PROPERTYCLOSEDREQUESTS";
export const SET_REQUEST = "SET_REQUEST";
